.datatable {
    @apply overflow-x-visible;

    .rdt {
        &_Table {
            @apply text-black bg-transparent;
        }

        &_TableHead {
            .rdt_TableCol {
                @apply text-xs font-semibold uppercase leading-6 text-gray-700;
            }
        }

        &_TableHeadRow {
            @apply border-0 bg-transparent;
        }

        &_TableCol {
            @apply text-black;

            .rdt_TableCol_Sortable {
                div, span {
                    @apply transition;
                }
    
                div {
                    @apply mr-2;
                }
    
                span {
                    @apply p-1;
                }
    
                &:hover {
                    @apply text-gray-900;
                }
    
                &[aria-pressed=true] {
                    @apply text-primary-500;
    
                    &:hover {
                        @apply text-primary-600;
                    }
                }
            }

            &:last-of-type .rdt_TableCol_Sortable div {
                @apply mr-0 ml-2;
            }
        }

        &_TableBody {
            .rdt_TableRow {
                @apply mb-2 text-base text-gray-900 border-0 rounded-md shadow transition cursor-pointer;

                &:last-of-type {
                    @apply mb-0;
                }

                &:focus {
                    @apply outline-none;
                }

                &:hover {
                    @apply outline-none bg-white border-0 shadow-md transform -translate-y-1;
                }
            }
        }
    }
}