.PresetDateRangePicker {
    &_panel {
        padding: 0 22px 11px;
    }

    &_button {
        @apply relative h-full box-border py-1 px-3 mr-2 text-center font-bold text-primary-600 bg-opacity-0 bg-none border-2 border-primary-600 overflow-visible cursor-pointer;
        font: inherit;
        line-height: normal;

        &:active {
            @apply outline-none;
        }

        &__selected {
            @apply text-white bg-primary-600;
        }
    }
}

.SingleDatePickerInput {
    @apply bg-white rounded-md;

    &__withBorder {
        @apply rounded-md border border-gray-300;
    }

    &__rtl {
        direction: rtl;
    }

    &__disabled {
        @apply bg-gray-100;
    }

    &__block {
        @apply block;
    }

    &__showClearDate {
        @apply pr-0;

        .DateInput_input {
            @apply pr-10;
        }
    }

    &_clearDate {
        @apply absolute right-0 inset-y-0 py-0 pl-0 pr-3 m-0 transform translate-y-0 flex items-center bg-opacity-0 bg-none border-0 overflow-visible cursor-pointer;

        &__default:focus,
        &__default:hover {
            @apply bg-gray-400;
            border-radius: 50%;
        }

        &__small {
            padding: 6px;
        }

        &__hide {
            @apply invisible;
        }

        &_svg {
            @apply h-3 align-middle fill-current text-gray-700;
            width: 15px;

            &__small {
                height: 9px;
            }
        }
    }

    &_calendarIcon {
        @apply inline-block align-middle bg-opacity-0 bg-none border-0 overflow-visible cursor-pointer;
        color: inherit;
        font: inherit;
        line-height: normal;
        padding: 10px;
        margin: 0 5px 0 10px;

        &_svg {
            @apply align-middle fill-current text-gray-700;
            height: 15px;
            width: 14px;
        }
    }

    .DateInput_input {
        @apply text-gray-900 bg-gray-100 p-2;

        &__focused {
            @apply border-primary-100 bg-primary-100 text-primary-500 placeholder-primary-500 font-semibold shadow-none;
        }
    }
}

.SingleDatePicker {
    @apply relative inline-block;

    &__block {
        @apply block;
    }

    &_picker {
        @apply absolute bg-white;
        z-index: 1;

        &__rtl {
            direction: rtl;
        }

        &__directionLeft {
            @apply left-0;
        }

        &__directionRight {
            @apply right-0;
        }

        &__portal {
            @apply fixed top-0 left-0 h-full w-full bg-black bg-opacity-30;
        }

        &__fullScreenPortal {
            @apply bg-white;
        }
    }

    &_closeButton {
        @apply absolute top-0 right-0 bg-opacity-0 bg-none border-0 overflow-visible cursor-pointer;
        color: inherit;
        font: inherit;
        line-height: normal;
        padding: 15px;
        z-index: 2;

        &:focus,
        &:hover {
            @apply no-underline;
            color: darken(#c5c7c9, 10%);
        }

        &_svg {
            @apply fill-current text-gray-500;
            height: 15px;
            width: 15px;
        }
    }
}

.CalendarDay {
    @apply text-sm text-center font-medium box-border cursor-pointer rounded-full;

    &:active {
        @apply outline-none;
    }

    &__defaultCursor {
        @apply cursor-default;
    }

    &__default {
        @apply bg-white border-0 text-gray-900;

        &:hover {
            @apply border-0 bg-gray-200;
            color: inherit;
        }
    }

    &__hovered_offset {
        @apply border-0 bg-gray-100;
        color: inherit;
    }

    &__outside {
        @apply border-0 bg-white text-gray-900;

        &:hover {
            @apply border-0;
        }
    }

    &__blocked_minimum_nights {
        @apply bg-white border-0 text-gray-500;

        &:active,
        &:hover {
            @apply bg-white text-gray-500;
        }
    }

    &__highlighted_calendar {
        @apply text-white bg-primary-500;

        &:active,
        &:hover {
            @apply text-gray-900 font-semibold bg-gray-200;
        }
    }

    &__selected_span {
        @apply border-0 rounded-none text-primary-500 font-semibold bg-primary-100;

        &:active,
        &:hover {
            @apply border-0 text-primary-500 font-semibold bg-primary-200;
        }
    }

    &__selected {
        &,
        &__selected:active,
        &__selected:hover {
            @apply border-0 text-white font-semibold bg-primary-500;
        }

        &_start,
        &__start:active,
        &__start:hover {
            @apply border-0 rounded-r-none text-white font-semibold bg-primary-500;
        }

        &_end,
        &_end:active,
        &_end:hover {
            @apply border-0 rounded-l-none text-white font-semibold bg-primary-500;
        }
    }

    &__hovered_span,
    &__hovered_span:hover {
        @apply border-0 bg-primary-100 text-primary-500 rounded-none;

        &:active {
            @apply border-0 bg-primary-200 text-primary-500 rounded-none;
        }
    }

    &__blocked_calendar,
    &__blocked_calendar:active,
    &__blocked_calendar:hover {
        @apply border-0 bg-gray-500 text-gray-700;
    }

    &__blocked_out_of_range,
    &__blocked_out_of_range:active,
    &__blocked_out_of_range:hover {
        @apply bg-white border-0 text-gray-400;
    }

    &__hovered_start_first_possible_end {
        @apply border-0 bg-gray-200;
    }

    &__hovered_start_blocked_min_nights {
        @apply border-0 bg-gray-200;
    }
}

.CalendarMonth {
    @apply bg-white text-center align-top select-none;

    &_table {
        @apply border-collapse;
        border-spacing: 0;
    }

    &_verticalSpacing {
        @apply border-separate;
    }

    &_caption {
        @apply text-lg text-center text-gray-900 pt-4 pb-8 leading-8;
        caption-side: initial;

        &__verticalScrollable {
            @apply pt-3 pb-2;
        }
    }
}

.CalendarMonthGrid {
    @apply bg-white text-left z-0;

    &__animating {
        z-index: 1;
    }

    &__horizontal {
        @apply absolute;
        left: 9px;
    }

    &__vertical,
    &__vertical_scrollable {
        @apply my-0 mx-auto;
    }

    &_month {
        &__horizontal {
            @apply inline-block align-top min-h-full;
        }

        &__hideForAnimation {
            @apply absolute opacity-0 pointer-events-none;
            z-index: -1;
        }

        &__hidden {
            @apply invisible;
        }
    }
}

.DayPickerNavigation {
    @apply relative;
    z-index: 2;

    &__horizontal {
        @apply h-0;
    }

    &__verticalScrollable_prevNav {
        z-index: 1;
    }

    &__verticalDefault {
        @apply absolute bottom-0 left-0 w-full;
        height: 52px;
    }

    &__verticalScrollableDefault {
        @apply relative;
    }

    &__bottom {
        @apply h-auto;
    }

    &__bottomDefault {
        @apply flex justify-between;
    }

    &_button {
        @apply cursor-pointer select-none border-0 p-0 m-0;

        &__default {
            @apply border border-gray-300 bg-white text-gray-800;

            &:focus,
            &:hover {
                @apply border border-gray-600;
            }

            &:active {
                @apply bg-gray-100;
            }
        }

        &__disabled {
            @apply border border-gray-100 cursor-default;

            &:focus,
            &:hover {
                @apply border border-gray-100;
            }

            &:active {
                @apply bg-opacity-0 bg-none;
            }
        }

        &__horizontal {
            @apply absolute top-4 p-1 rounded-md bg-white;

            &:first-child {
                @apply left-4;
            }

            &:last-child {
                @apply right-4;
            }
        }

        &__horizontalDefault {
            @apply absolute;
            top: 18px;
            line-height: 0.78;
            border-radius: 3px;
            padding: 6px 9px;
        }

        &__verticalDefault {
            @apply relative inline-block h-full w-1/2 text-center bg-white shadow-md;
            padding: 5px;
        }
    }

    &_bottomButton__horizontalDefault {
        @apply static;
        margin: -10px 22px 30px;
    }

    &_leftButton__horizontalDefault {
        left: 22px;
    }

    &_rightButton__horizontalDefault {
        right: 22px;
    }

    &_nextButton__verticalDefault {
        border-left: 0;
    }

    &_nextButton__verticalScrollableDefault,
    &_prevButton__verticalScrollableDefault {
        width: 100%;
    }

    &_svg {
        &__horizontal {
            @apply block fill-current text-gray-700;
            height: 19px;
            width: 19px;
        }

        &__vertical {
            @apply fill-current text-gray-900;
            height: 42px;
            width: 42px;
        }

        &__disabled {
            @apply text-gray-100 fill-current;
        }
    }
}

.DayPicker {
    @apply relative text-left bg-white z-10;

    &_transitionContainer {
        height: 276px !important;
    }

    &__horizontal {
        @apply bg-white;
    }

    &__verticalScrollable {
        @apply h-full;
    }

    &__hidden {
        @apply invisible;
    }

    &__withBorder {
        @apply shadow-lg;
        border-radius: 3px;
    }

    &_portal {
        &__horizontal {
            @apply absolute left-1/2 top-1/2 shadow-none;
        }

        &__vertical {
            position: initial;
        }
    }

    &_focusRegion {
        @apply outline-none;
    }

    &_calendarInfo__horizontal,
    &_wrapper__horizontal {
        @apply inline-block align-top;
    }

    &_weekHeaders {
        @apply relative;

        &__horizontal {
            margin-left: 9px;
        }
    }

    &_weekHeader {
        @apply absolute text-left text-gray-700;
        top: 58px;
        z-index: 2;

        &__vertical {
            @apply left-1/2;
        }

        &__verticalScrollable {
            @apply top-0 left-0 table-row w-full ml-0 text-center border-b border-gray-400 bg-white;
        }

        &_ul {
            @apply mt-0 mb-1 px-0 text-sm;
            list-style: none;
        }

        &_li {
            @apply inline-block text-center leading-5;
        }
    }

    &_transitionContainer {
        @apply relative overflow-hidden;
        border-radius: 3px;

        &__horizontal {
            -webkit-transition: height 0.2s ease-in-out;
            -moz-transition: height 0.2s ease-in-out;
            transition: height 0.2s ease-in-out;
        }

        &__vertical {
            @apply w-full;
        }

        &__verticalScrollable {
            @apply absolute inset-0 h-full pt-5 overflow-y-scroll;
        }
    }
}

.DatePickerColor-gray .DateInput {
    @apply bg-gray-100 rounded-md;

    &_input {
        @apply bg-gray-100 border-gray-100;
    }
}

.DatePickerColor-light .DateInput {
    &_input {
        @apply border-gray-100;
    }
}

.DatePickerColor-gray .DateRangePickerInput {
    @apply bg-gray-100 drop-shadow-sm shadow-md border border-gray-300;
}

.DatePickerColor-white .DateInput {
    @apply bg-white border-white rounded-md;

    &_input {
        @apply bg-white border-white;
    }
}

.DatePickerColor-white .DateRangePickerInput {
    @apply bg-white drop-shadow-sm shadow-md;
}

.DateInput {
    @apply relative block align-middle m-0 p-0 bg-gray-100 rounded-md;

    &-center-input-value {
        & input {
            text-align: center;
        }
    }

    &__small {
        width: 97px;
    }

    &__block {
        @apply w-full;
    }

    &__disabled {
        @apply bg-gray-100 text-gray-500;
    }

    &_input {
        @apply text-center w-full hover:border-gray-200 rounded-md font-normal py-2.5 pl-2 pr-2 text-sm leading-6 text-gray-900 bg-gray-100 shadow-sm hover:shadow placeholder-gray-600 transition duration-200 ease-linear;

        &__small {
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.2px;
            padding: 7px 7px 5px;
        }

        &__regular {
            font-weight: auto;
        }

        &__readOnly {
            @apply select-none;
        }

        &__focused {
            @apply border-gray-300 bg-gray-100 outline-none placeholder-gray-600 shadow-inner;
        }

        &__disabled {
            @apply bg-gray-100;
        }
    }

    &_screenReaderMessage {
        @apply absolute w-px h-px -m-px p-0 border-0 overflow-hidden;
        clip: rect(0, 0, 0, 0);
    }

    &_fang {
        @apply absolute w-5 h-3;
        left: 22px;
        z-index: -1;
        display: none;
    }

    &_fangShape {
        fill: #fff;
    }

    &_fangStroke {
        @apply text-gray-400 stroke-current;
        fill: transparent;
    }
}

.DateRangePickerInput {
    @apply flex items-center bg-gray-100 rounded-md px-1.5 py-1;

    .DateInput {
        @apply cursor-pointer w-full py-px;

        &_input {
            @apply px-1 py-0.5 shadow-none hover:shadow-none hover:border-gray-100 transition duration-200 ease-linear border hover:border-gray-100;

            &__focused {
                @apply border-primary-100 bg-primary-100 text-primary-500 placeholder-primary-500 font-semibold shadow-none;
            }
        }
    }

    &__disabled {
        @apply bg-gray-100;
    }

    &__withBorder {
        @apply border border-gray-400;
    }

    &__rtl {
        direction: rtl;
    }

    &__block {
        @apply flex;
    }

    &__showClearDates {
        @apply w-full;
    }

    &_arrow {
        @apply inline-flex items-center flex-shrink-0 mx-4 text-gray-900;

        &_svg {
            @apply align-middle w-6 h-6 fill-current text-gray-900;
        }
    }

    &_clearDates {
        @apply absolute right-0 inset-y-0 py-0 pl-0 pr-2 m-0 transform translate-y-0 flex items-center bg-opacity-0 bg-none border-0 overflow-visible cursor-pointer;

        &__small {
            padding: 6px;
        }

        &_default:focus,
        &_default:hover {
            @apply bg-gray-400;
            border-radius: 50%;
        }

        &__hide {
            @apply invisible;
        }

        &_svg {
            @apply h-3 align-middle fill-current text-gray-700;
            width: 15px;

            &__small {
                height: 9px;
            }
        }
    }

    &_calendarIcon {
        @apply inline-block align-middle bg-opacity-0 bg-none border-0 overflow-visible cursor-pointer;
        color: inherit;
        font: inherit;
        line-height: normal;
        padding: 10px;
        margin: 0 5px 0 10px;

        &_svg {
            @apply align-middle fill-current text-gray-700;
            height: 15px;
            width: 14px;
        }
    }

    &_arrow {
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }
}

.DateRangePicker {
    @apply relative inline-block;

    &__block {
        @apply block;
    }

    &_picker {
        @apply absolute bg-white;
        z-index: 100;

        &__rtl {
            direction: rtl;
        }

        &__directionLeft {
            @apply left-0;
        }

        &__directionRight {
            @apply right-0;
        }

        &__portal {
            @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-30;
        }

        &__fullScreenPortal {
            @apply bg-white;
        }
    }

    &_closeButton {
        @apply absolute top-0 right-0 bg-opacity-0 bg-none border-0 overflow-visible cursor-pointer;
        color: inherit;
        font: inherit;
        line-height: normal;
        padding: 15px;
        z-index: 2;

        &:focus,
        &:hover {
            @apply no-underline;
            color: darken(#c5c7c9, 10%);
        }

        &_svg {
            @apply fill-current text-gray-500;
            height: 15px;
            width: 15px;
        }
    }
}
