.select {
    & &--is-disabled {
        opacity: 0.6;
    }

    &:focus {
        @apply outline-none;
    }

    & &__control {
        @apply border border-gray-100 hover:border-gray-200 rounded-md font-normal text-sm leading-6 text-gray-900 bg-gray-100 shadow-sm hover:shadow placeholder-gray-600 transition duration-200 ease-linear;

        &--is-focused {
            @apply border-gray-300 bg-gray-100 outline-none placeholder-gray-600 shadow-inner;
        }

        &--is-disabled {
            // background-color: $gray-300;
        }
    }

    // & &__value-container {
    //     @apply flex-nowrap p-1.5;
    // }

    & &__placeholder {
        @apply truncate m-0 text-sm leading-6 text-gray-600;
    }

    &.select-error &__placeholder {
        color: rgba(239, 68, 68, 1);
    }

    & [class*='Input'] {
        @apply h-6 m-0 p-0 text-gray-700;
    }

    & &__input {
        @apply block m-0 text-sm leading-6 text-gray-700;
    }

    & &__single-value {
        @apply m-0 text-sm leading-6 text-gray-700;
    }

    & &__multi-value {
        @apply my-0 ml-0 mr-2 bg-primary-100 hover:bg-primary-200 rounded-md transition duration-200 ease-linear cursor-pointer;

        &__label {
            @apply py-0.5 pl-2 pr-1 text-sm font-semibold leading-6 text-primary-500;
        }

        &__remove {
            @apply py-0 px-1 hover:bg-transparent rounded-md;
        }
    }

    & &__indicators {
        @apply items-stretch;
    }

    & &__indicator-separator {
        @apply w-0 m-0 bg-transparent;
    }

    & &__dropdown-indicator,
    & &__clear-indicator {
        @apply flex items-center py-0 pl-0 pr-3 cursor-pointer;
    }

    & &__menu {
        @apply m-0 bg-white shadow-md overflow-hidden;

        &-list {
            @apply p-1 space-y-1;
        }

        &-notice {
            @apply py-3 px-2 text-sm leading-6 text-gray-500;
        }
    }

    & &__option {
        @apply p-0 rounded-md text-sm leading-6 text-gray-700 hover:text-primary-500 hover:bg-primary-100 cursor-pointer transition duration-200 ease-linear;

        &--is-selected {
            @apply font-semibold text-primary-500 bg-primary-100 hover:bg-primary-200;
        }

        &--is-focused {
            @apply bg-white;
        }
    }

    &-sm {
        & [class*='Input'] {
            @apply h-6;
        }

        &.select {
            &__control {
                @apply h-8;
                min-height: 32px;
            }

            // &__value-container {
            //     @apply -mt-0.5 py-1 px-2;
            // }

            &__placeholder {
                @apply leading-6;
            }

            &__input {
                @apply block text-sm leading-6;
            }

            &__dropdown-indicator {
                padding: 6px;
            }

            &__clear-indicator {
                padding: 6px 0 6px 6px;
            }

            &__single-value {
                @apply leading-6;
            }

            &__multi-value {
                @apply -mt-px;

                &__label {
                    @apply pr-0.5 pl-1 text-sm leading-6;
                }

                &__remove {
                    @apply py-0 px-0.5;
                }
            }

            &__menu {
                &-notice {
                    @apply p-2 text-sm leading-4 text-gray-500;
                }
            }

            &__option {
                @apply p-2;
            }
        }
    }
}
