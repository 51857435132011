.rst {
    &__row {
        @apply flex items-center p-3 rounded-md bg-white shadow-md;
    }

    &__rowWrapper {
        @apply p-3;
    }

    &__moveHandle {
        @apply w-6 h-6 border-0 shadow-none cursor-move;
        background: transparent
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' fill='%23c5c7c9' /%3E%3C/svg%3E");
    }

    &__rowContents {
        @apply relative flex items-center justify-between flex-1 h-auto p-0 border-0 bg-transparent shadow-none;

        min-width: 420px;
    }

    &__rowLabel {
        @apply flex-1 p-0 mx-2;
    }

    &__rowTitle {
        @apply flex items-center;
    }

    &__rowLandingPad::before,
    &__rowCancelPad::before {
        @apply absolute inset-0 border-2 border-dashed rounded-md border-gray-300 bg-gray-50;
    }

    &__rowCancelPad::before {
        @apply border-red-500 bg-gray-50;
    }

    &__lineHalfHorizontalRight::before,
    &__lineFullVertical::after,
    &__lineHalfVerticalTop::after,
    &__lineHalfVerticalBottom::after {
        @apply absolute bg-gray-300;
    }

    &__lineHalfHorizontalRight::before {
        @apply h-1;
        width: calc(50% + 12px);
        top: calc(50% - 2px);
        right: -12px;
        z-index: -1;
    }

    &__lineFullVertical::after,
    &__lineHalfVerticalTop::after,
    &__lineHalfVerticalBottom::after {
        @apply w-1;
    }

    &__lineChildren::after {
        @apply absolute w-1 bg-gray-300;
        left: calc(50% - 2px);
    }

    &__lineFullVertical::after,
    &__lineHalfVerticalTop::after,
    &__lineHalfVerticalBottom::after {
        left: calc(50% - 2px);
    }

    &__lineHalfVerticalTop::after {
        height: calc(50% + 2px);
    }

    &__expandButton {
        background: #f3f4f5
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect fill='%23637590' x='4' y='11' width='16' height='2' rx='1'%3E%3C/rect%3E%3Crect fill='%23637590' transform='translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) ' x='4' y='11' width='16' height='2' rx='1'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E")
            no-repeat center;
    }

    &__collapseButton {
        background: #f3f4f5
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect fill='%23637590' x='4' y='11' width='16' height='2' rx='1'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E")
            no-repeat center;
    }

    &__collapseButton,
    &__expandButton {
        @apply appearance-none absolute w-6 h-6 -mt-3 -ml-3 border-0 rounded-full transform translate-x-0 translate-y-0 origin-center shadow-none cursor-pointer transition;

        background-size: 18px 18px;

        &:focus {
            @apply shadow-none;
        }

        &:hover:not(:active) {
            @apply w-6 h-6 transform translate-x-0 translate-y-0 scale-105 origin-center bg-gray-300 transition;

            background-size: 22px 22px;
        }
    }

    @keyframes arrow-pulse {
        0% {
            transform: translate(0, 0);
            opacity: 0;
        }
        30% {
            transform: translate(0, 300%);
            opacity: 1;
        }
        70% {
            transform: translate(0, 700%);
            opacity: 1;
        }
        100% {
            transform: translate(0, 1000%);
            opacity: 0;
        }
    }

    &__highlightLineVertical {
        z-index: 3;

        &::before {
            @apply absolute top-0 left-1/2 w-2 h-full -ml-1 bg-primary-500;
            content: '';
        }

        &::after {
            @apply absolute top-0 left-1/2 h-0 -ml-1;
            content: '';
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid white;
            animation: arrow-pulse 1s infinite linear both;
        }
    }

    &__highlightTopLeftCorner::before {
        @apply absolute top-1/2 right-0 -mt-1 border-t-8 border-l-8 border-primary-500 box-border;
        
        content: '';
        width: calc(50% + 4px);
        height: calc(50% + 4px);
        z-index: 3;
    }

    &__highlightBottomLeftCorner {
        z-index: 3;

        &::before {
            @apply absolute top-0 right-0 border-b-8 border-l-8 border-primary-500 box-border;
            content: '';
            width: calc(50% + 4px);
            height: calc(100% + 4px);
        }

        &::after {
            @apply absolute top-full right-0 h-0 -mr-3;
            content: '';
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 12px solid #0d6efd;
        }
    }
    &__tree > div > div {
        overflow-y: auto !important
    }
}
